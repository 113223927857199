<template>
  <div class="base-header">
    <div class="nav2">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          :class="{
            active: $route.name == item.path,
          }"
          @click="goMenu(item.id, item.path)"
        >
          <i :class="[item.icon]"></i>
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header",
  data() {
    return {
      menu: [
        {
          id: 1,
          img1: require("@/assets/img/nav2/b1.png"),
          img2: require("@/assets/img/nav2/b11.png"),
          icon: "iconfont icon-shouye ",
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          value: "箱子大厅",
        },
        {
          id: 2,
          img1: require("@/assets/img/nav2/b2.png"),
          img2: require("@/assets/img/nav2/b22.png"),
          icon: "iconfont icon-icon_duizhan ",
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "盲盒对战",
        },
        {
          id: 3,
          img1: require("@/assets/img/nav2/b3.png"),
          img2: require("@/assets/img/nav2/b33.png"),
          selState: false,
          icon: "hxd-iconfont hxd-roll",
          path: "Arena",
          child: ["Arena", "ArenaRoom"],
          value: "ROLL房",
        },
        {
          id: 4,
          img1: require("@/assets/img/nav2/b4.png"),
          img2: require("@/assets/img/nav2/b44.png"),
          icon: "iconfont icon-lucky_right",
          selState: false,
          path: "Ornament",
          child: ["Ornament", "OrnamentOpen", "OrnamentHistory", "OrnamentHistory2"],
          value: "幸运饰品",
        },
        {
          id: 5,
          img1: require("@/assets/img/nav2/b5.png"),
          img2: require("@/assets/img/nav2/b55.png"),
          selState: false,
          icon: "hxd-iconfont hxd-shangcheng",
          path: "Roll",
          child: ["Roll"],
          value: "饰品商城",
        },
        {
          id: 6,
          img1: require("@/assets/img/nav2/b6.png"),
          img2: require("@/assets/img/nav2/b66.png"),
          selState: false,
          icon: "hxd-iconfont hxd-mianfeijinbi",
          path: "Spread",
          child: ["Spread"],
          value: "活动推广",
        },
        {
          id: 7,
          img1: require("@/assets/img/nav2/b8.png"),
          img2: require("@/assets/img/nav2/b88.png"),
          selState: false,
          icon: "iconfont icon-beibao",
          path: "Dota",
          child: ["Dota"],
          value: "饰品背包",
        },
      ],
    };
  },
  methods: {
    goMenu(id, path) {
      this.$emit("goMenu", id, path);
    },
  },
};
</script>

<style lang="less" scoped>
.hxd-iconfont,
.iconfont {
  transition: all 0.1s linear;
  background: linear-gradient(90deg, #e9b10e, #e9b10e);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 18px;
}

i {
  margin-right: 5px;
}
.icon-beibao {
  font-size: 20px;
}
.hxd-mianfeijinbi {
  font-size: 26px;
}
.icon-lucky_right {
  font-size: 18px;
}
.hxd-shangcheng {
  font-size: 21px;
}
.icon-shouye {
  font-size: 20px;
}
.icon-icon_duizhan {
  font-size: 30px;
}
.hxd-roll {
  font-size: 23px;
}
.icon-icon_duizhan {
  font-size: 26px;
}
.icon-shouye {
  font-size: 18px;
}

.base-header {
  padding-left: 50px;
  .nav2 {
    height: 42px;
    ul {
      display: flex;
      height: 100%;
      .active {
        color: #ff9b0b;
        .iconfont {
          color: #ff9b0b !important;
        }
      }
      li {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-size: 15px;
        line-height: 38px;
        margin-left: 10px;
        white-space: nowrap;
        font-weight: bold;
      }
    }
  }
}
</style>
