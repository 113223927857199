<template>
  <div class="index-nav">
    <div class="nav-item" v-for="item in list" :key="item" @click="routerJump(item)">
      <img :src="item.img" alt="" srcset="" />
      <p>{{ item.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "福利",
          img: require("../../../assets/img/index/s0.031a9ae8.png"),
          routerName: "/Openbox?box_id=62",
        },
        {
          title: "ROLL",
          img: require("../../../assets/img/index/s1.618b4dde.png"),
          routerName: "/Arena",
        },
        {
          title: "充值",
          img: require("../../../assets/img/index/s2.e541bc20.png"),
          routerName: "/Payment",
        },
        {
          title: "CDK",
          img: require("../../../assets/img/index/s3.c678282b.png"),
          routerName: "/Payment?type=cdk",
        },
        {
          title: "客服",
          img: require("../../../assets/img/index/s4.3cdeae9e.png"),
        },
      ],
    };
  },
  methods: {
    routerJump(item) {
      if (item.title == "客服") {
        this.qqsGroup();
        return;
      }
      this.$router.push({
        path: item.routerName,
      });
    },
    qqsGroup() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
      if (isAndroid) {
        window.open("mqqwpa://im/chat?chat_type=wpa&uin=2011996970");
        return;
      }
      if (isIOS) {
        window.open(
          "mqqapi://card/show_pslcard?src_type=internal&source=sharecard&version=1&uin=2011996970"
        );
        return;
      }
      window.open(
        "https://wpa.qq.com/msgrd?v=3&uin=2011996970&site=qq&menu=yes&jumpflag=1"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.index-nav {
  height: 86px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 0;
  .nav-item {
    text-align: center;
    color: #ddd;
    font-size: 15px;
    img {
      width: 52px;
    }
  }
}
</style>
