<template>
  <div class="container" v-if="!showNav">
    <div class="flexleft">
      <ul>
        <li
          v-for="(item, index) in yidongmenu"
          :key="index"
          class="menu-item"
          :class="{
            'menu-active': item.path == $route.name,
          }"
          @click="goNavMenu(item.id, item.path, item.value)"
        >
          <div class="icon-img">
            <i :class="[item.icon, 'icon']"></i>
          </div>
          <div
            class="name"
            :class="item.path == $route.name ? 'item-val-bian' : 'item-val'"
            style="font-size: 12px; margin-top: 3px"
          >
            {{ item.value }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showNav", "yidongmenu", "openHongbao"],
  watch: {
    $route: {
      handler(value) {
        console.log(value);
      },
      immediate: true,
    },
  },
  methods: {
    //左侧导航
    goNavMenu(id, path, name) {
      this.$router.push({
        path: `/${path}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hxd-iconfont,
.iconfont {
  transition: all 0.1s linear;
  background: linear-gradient(90deg, rgb(137, 139, 156), rgb(137, 139, 156));
  -webkit-background-clip: text;
  color: transparent;
  font-size: 18px;
}

.container {
  height: 60px;

  .icon-beibao {
    font-size: 25px;
  }

  .hxd-shangcheng {
    font-size: 27px;
  }
  .hxd-roll {
    font-size: 31px;
  }
  .icon-duizhan2x {
    font-size: 28px;
  }
  .icon-shouye {
    font-size: 23px;
  }
  .icon-express-package-full {
    font-size: 23px;
  }
  .icon-xingyun {
    font-size: 21px;
  }
  .icon-lucky_right {
    font-size: 21px;
  }
}

.menu-active {
  // border-top: 2px solid #fbbd02 !important;
  border-width: 5px;
  background: #15151c !important;
  i,
  div {
    color: #fbbd02;
  }
}
.name {
  margin-bottom: 5px;
}
.flexleft {
  height: 65px;
  // position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  background-size: 100% 100%;
  background-color: #191c23;
  // background-image: url("../assets/img/nav2/bee1140eec6fee1756bbf894b81123e.jpg");
  color: #848492;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    height: 100%;
    overflow: auto;
    li {
      display: flex;
      border-top: 2px solid transparent;
      flex-direction: column;
      height: 100%;
      align-items: center;
      box-sizing: border-box;
      width: 100px;
      justify-content: space-between;
      color: rgb(137, 139, 156);
      .icon-img {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.item-val-bian {
  color: rgb(137, 139, 156);
}
</style>
